import React from "react"
import PricePage from "../../components/pricePage"
import {graphql} from "gatsby"

export default (props) => (<PricePage
  title={props.data.strapiSeo.title}
  data={props.data}
  location={props.location}
  contacts={props.pageContext.contacts}
  description={props.data.strapiSeo.description}
  body={props.data.strapiSeo.body}
/>)

export const pageQuery = graphql`
  query($code: String = "") {
        strapiHtmlBlock(code: {eq: $code}) {
            body
        },
        strapiSeo(code: {eq: $code}) {
            title,
            description,
            body
        }
    }
`